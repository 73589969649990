import { template as template_9d276bf3f24b45ad9e29d59255c6546a } from "@ember/template-compiler";
const FKLabel = template_9d276bf3f24b45ad9e29d59255c6546a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
