import { template as template_e1d3668bf2374ec0bffa15c9b2e34185 } from "@ember/template-compiler";
const FKControlMenuContainer = template_e1d3668bf2374ec0bffa15c9b2e34185(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
