import { template as template_6fdeaf5de06846ddb89cccd16a13ac8e } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_6fdeaf5de06846ddb89cccd16a13ac8e(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
